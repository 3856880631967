
import ActivityCard2 from '@/views/mc-admin-library-manager/ActivityCard2.vue'
import DataTable from '@/views/mc-admin-library-manager/TheActivitiesDatatable.vue'
// import StatisticsWidget from '@/components/mc-admin-widgets/StatisticsWidget1.vue'
import SortingDropdown from '@/views/mc-admin-library-manager/TheSortingDropdown.vue'
import { defineComponent, ref, onMounted, computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import {
  getStatusBadge,
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'
import {
  buildTemplateImageUrl,
  buildTemplateImageUri,
  formatActivitiesTableDate,
  formatSecondsToMinutes,
  getEtagForTemplate,
  formatHumanDate
} from '@/core/mc-admin-helpers/activity-template'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { ActivityTemplateFilter } from '@/store/modules/TemplateModule'
import TheDeleteActivityModal from '@/views/mc-admin-library-manager/TheDeleteActivityModal.vue'
import ThePublicSessionsModal from '@/views/mc-admin-library-manager/ThePublicSessionsModal.vue'
import { MenuComponent } from '@/assets/ts/components'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ElNotification } from 'element-plus/es'
import ReadySwitch from '@/views/mc-admin-library-manager/ReadySwitch.vue'
export default defineComponent({
  name: 'library-manager',
  components: {
    // StatisticsWidget,
    ActivityCard2,
    DataTable,
    SortingDropdown,
    TheDeleteActivityModal,
    ThePublicSessionsModal,
    ReadySwitch
  },
  setup () {
    const onActivityCardSwitched = (value) => {
      console.log('switched: ', value)
    }
    const handleMainActionClicked = (newPath) => {
      window.location.href = newPath
    }
    const activitySettings = ref() as any

    const handleActivitySettingsVisibility = () => {
      if (!activitySettings.value) return
      console.log('activitySettings.value', activitySettings.value)
      activitySettings.value.visible = !activitySettings.value.visible
    }
    const rules = ref({
      title: [
        {
          required: true,
          message: 'Please input activity title',
          trigger: 'blur'
        },
        {
          max: 55,
          message: 'The title cannot be greater then 55 characters',
          trigger: 'blur'
        }
      ]
    })
    const formRef = ref<null | HTMLFormElement>(null)
    const currentAccount = computed(() => store.getters.currentAccount)
    // const currentAccount = () => {
    //   return store.getters.currentAccount
    // }

    const currentCompany = computed(() => store.getters.getCompany)
    const onPublishToCompanyClicked = (activityTemplate) => {
      selectedActivityTemplate.value = activityTemplate
      confirmModalPublishToCompany.value = true
    }

    const onPublishToPublicClicked = (activityTemplate) => {
      selectedActivityTemplate.value = activityTemplate
      confirmModalPublishToPublic.value = true
    }

    const onRemoveFromCompanyViewClicked = (activityTemplate) => {
      selectedActivityTemplate.value = activityTemplate
      confirmModalRemoveFromCompanyView.value = true
    }

    const onRemoveFromPublicViewClicked = (activityTemplate) => {
      selectedActivityTemplate.value = activityTemplate
      confirmModalRemoveFromPublicView.value = true
    }

    const onDuplicateClicked = (activityTemplate) => {
      selectedActivityTemplate.value = activityTemplate
      duplicateForm.title = 'Copy of ' + activityTemplate.title
      confirmModalDuplicate.value = true
    }

    const selectedActivityTemplate = ref({}) as any
    const duplicateForm = reactive({
      title: ''
    })
    const onModalConfirmRemoveFromCompanyViewClicked = () => {
      const payload = { } as any
      payload.mode = 'local'
      payload.templateUuid = selectedActivityTemplate.value.activityTemplateUuid
      store.dispatch(Actions.API_PUBLISH_TEMPLATE, payload).then((response) => {
        confirmModalRemoveFromCompanyView.value = false
        ElNotification.success({
          message: 'Activity "' + response.payload.title + '" was successfully removed from company view',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }).catch((response) => {
        console.log('error: ', response)
      })
    }

    const onModalConfirmRemoveFromPublicViewClicked = () => {
      const payload = { } as any
      payload.mode = 'local'
      payload.templateUuid = selectedActivityTemplate.value.activityTemplateUuid
      store.dispatch(Actions.API_PUBLISH_TEMPLATE, payload).then((response) => {
        console.log('response:', response)
        confirmModalRemoveFromPublicView.value = false
        ElNotification.success({
          message: 'Activity "' + response.payload.title + '" was successfully removed from public view',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }).catch((response) => {
        console.log('error: ', response)
      })
    }

    const onModalConfirmPublishToPublicClicked = () => {
      const payload = { } as any
      payload.mode = 'public'
      payload.templateUuid = selectedActivityTemplate.value.activityTemplateUuid
      store.dispatch(Actions.API_PUBLISH_TEMPLATE, payload).then((response) => {
        confirmModalPublishToPublic.value = false
        ElNotification.success({
          message: 'Activity "' + response.payload.title + '" was successfully published to public',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }).catch((response) => {
      })
    }

    const onModalConfirmPublishToCompanyClicked = () => {
      const payload = { } as any
      payload.mode = 'company'
      payload.templateUuid = selectedActivityTemplate.value.activityTemplateUuid
      store.dispatch(Actions.API_PUBLISH_TEMPLATE, payload).then((response) => {
        confirmModalPublishToCompany.value = false
        ElNotification.success({
          message: 'Activity "' + response.payload.title + '" was successfully published to company',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }).catch((response) => {
      })
    }
    const duplicateActivityInProgress = ref(false)
    const formValidation = ref(false)
    const onModalConfirmDuplicateClicked = () => {
      if (!formRef.value) return

      formRef.value.validate((valid) => {
        if (!valid) {
          formValidation.value = false
          return false
        }
        formValidation.value = true
      })

      if (!formValidation.value) return

      duplicateActivityInProgress.value = true
      console.log('selectedActivityTemplate', selectedActivityTemplate)
      const payload = {} as any
      payload.templateUuid = selectedActivityTemplate.value.activityTemplateUuid
      payload.title = duplicateForm.title

      store.dispatch(Actions.API_DUPLICATE_TEMPLATE, payload).then((response) => {
        confirmModalDuplicate.value = false
        store.commit(Mutations.ADD_NEW_TEMPLATE_TO_ALL_TEMPLATES, response.payload)
        window.location.href = '/builder/' + response.payload.activityTemplateUuid

        ElNotification.success({
          message: 'Activity "' + response.payload.title + '" was successfully duplicated',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
      }).catch((response) => {
        duplicateActivityInProgress.value = false
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        }).then(okay => {
          // if (okay) {
          //   createActivityLoading.value = false
          // }
        })
      })
    }

    const templateToDelete = ref({})
    const activityTemplate = ref({})
    const onShowDelete = (template) => {
      console.log(template)
      templateToDelete.value = template
    }

    const onShowPublicSessions = (template) => {
      console.log('activityTemplate', template)
      activityTemplate.value = template
    }

    const tableHeader = ref([
      {
        name: 'NAME AND DESCRIPTION',
        key: 'nameAndDescription',
        sortable: true
      },
      {
        name: 'LAST ACTION',
        key: 'lastAction',
        sortable: true
      },
      {
        name: 'VISIBILITY',
        key: 'visibility',
        sortable: true
      },
      {
        name: 'DURATION',
        key: 'duration',
        sortable: true
      },
      {
        name: 'STATUS',
        key: 'status',
        sortable: true
      },
      {
        name: 'Actions',
        key: 'actions'
      }
    ])
    const activitySwitched = ref<boolean>(false)
    const confirmModalPublishToCompany = ref(false)
    const confirmModalPublishToPublic = ref(false)
    const confirmModalRemoveFromCompanyView = ref(false)
    const confirmModalRemoveFromPublicView = ref(false)
    const confirmModalDuplicate = ref(false)
    const isActivitySwitched = (status) => ref(computed(() => {
      return status === 2
    }))

    const onSorted = (sortBy) => {
      activeSortBy.value = sortBy
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1),
          status: activeFilterBy.value
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[sortBy] = 'desc'
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
    }

    const onFilterSelected = (filterType) => {
      activeFilterBy.value = filterType
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1),
          status: filterType
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, payload)
    }

    const activeSortBy = ref<string>('lastOpened')
    const activeFilterBy = ref<number>(0)

    const tableSearch = ref<string>('')
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(1)

    const store = useStore()

    const tableData = ref(computed(() => store.getters.getActivityTemplates))
    const latestTemplates = ref(computed(() => store.getters.getLatestTemplates))
    const tableLoading = computed(() => store.getters.getActivityTemplatesRequestPending)

    const templatesCount = computed(() => store.getters.getActivityTemplatesCount)
    const tablePagesCount = computed(() => Math.ceil(templatesCount.value / itemsPerPage.value))

    const allTemplatesCount = computed(() => store.getters.getAllTemplatesCount)
    const readyTemplatesCount = computed(() => store.getters.getReadyTemplatesCount)
    const draftTemplatesCount = computed(() => store.getters.getDraftTemplatesCount)

    const sessionsInProgressCount = computed(() => store.getters.getActivitySessionsInProgressCount)
    const sessionsScheduledCount = computed(() => store.getters.getActivitySessionsScheduledCount)
    const sessionsFinishedCount = computed(() => store.getters.getActivitySessionsFinishedCount)

    let timeoutId = 0 as any

    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          title: tableSearch.value,
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1),
          status: activeFilterBy.value
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      const countPayload: ActivityTemplateFilter = { filter: { title: tableSearch.value, status: activeFilterBy.value } }
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, countPayload).then(() => {
            MenuComponent.reinitialization()
          })
        })
      }, 500)
    }

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (page - 1),
          status: activeFilterBy.value
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      if (tableSearch.value !== '') {
        payload.filter.title = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
    }

    const onItemsPerPageChange = (rowsPerPage, page) => {
      itemsPerPage.value = rowsPerPage
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          limit: rowsPerPage,
          offset: rowsPerPage * (selectedPage.value - 1),
          status: activeFilterBy.value
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      if (tableSearch.value !== '') {
        payload.filter.title = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
    }

    const onDeleteAction = (activitySessionUuid) => {
      // get the latest activity templates
      const latestPayload: ActivityTemplateFilter = { filter: { limit: 5, offset: 0 } }
      latestPayload.filter.orderBy = {}
      latestPayload.filter.orderBy[activeSortBy.value] = 'desc'

      store.dispatch(MutationsActions.API_GET_MUTATE_LATEST_ACTIVITY_TEMPLATES, latestPayload).then(() => {
        MenuComponent.reinitialization()
      })
    }

    onMounted(() => {
      const latestPayload: ActivityTemplateFilter = { filter: { limit: 5, offset: 0, orderBy: { lastOpened: 'desc' } } }
      watch(() => currentCompany.value, (updated, oldAccount) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANY_BY_UUID, currentAccount.value.company.uuid)
      store.dispatch(MutationsActions.API_GET_MUTATE_LATEST_ACTIVITY_TEMPLATES, latestPayload).then(() => {
        const payload: ActivityTemplateFilter = { filter: { limit: itemsPerPage.value, offset: 0, orderBy: { lastOpened: 'desc' } } }
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT).then(() => {
            store.dispatch(MutationsActions.API_GET_MUTATE_ALL_TEMPLATES_COUNT)
            const payloadReadyCounter : ActivityTemplateFilter = { filter: { status: 2 } }
            store.dispatch(MutationsActions.API_GET_MUTATE_READY_TEMPLATES_COUNT, payloadReadyCounter)
            const payloadDraftCounter : ActivityTemplateFilter = { filter: { status: 1 } }
            store.dispatch(MutationsActions.API_GET_MUTATE_DRAFT_TEMPLATES_COUNT, payloadDraftCounter)
            // dropdown menus need to be reinitialized
            MenuComponent.reinitialization()
          })
        })
      })

      setCurrentPageTitle('Your library')
      setCurrentPageTitleButton({ name: 'Build', url: 'activity-manager', class: 'houston-btn-dark houston-btn-bounce' })
    })

    return {
      handleMainActionClicked,
      tableHeader,
      tableData,
      tableSearch,
      tableLoading,
      tablePagesCount,
      sessionsInProgressCount,
      sessionsScheduledCount,
      sessionsFinishedCount,
      latestTemplates,
      selectedActivityTemplate,
      onItemsSearch,
      onItemsPerPageChange,
      onCurrentPageChange,
      onDeleteAction,
      getMedia,
      getStatusBadge,
      formatListDate,
      formatActivitiesTableDate,
      formatSecondsToMinutes,
      formatListHourInterval,
      formatHumanDate,
      onActivityCardSwitched,
      activitySwitched,
      isActivitySwitched,
      onSorted,
      onShowDelete,
      onFilterSelected,
      readyTemplatesCount,
      draftTemplatesCount,
      templatesCount,
      activeFilterBy,
      allTemplatesCount,
      buildTemplateImageUrl,
      buildTemplateImageUri,
      getEtagForTemplate,
      templateToDelete,
      currentAccount,
      currentCompany,
      confirmModalPublishToCompany,
      onPublishToCompanyClicked,
      onModalConfirmPublishToCompanyClicked,
      confirmModalRemoveFromCompanyView,
      onModalConfirmRemoveFromCompanyViewClicked,
      onRemoveFromCompanyViewClicked,
      onModalConfirmPublishToPublicClicked,
      confirmModalPublishToPublic,
      onPublishToPublicClicked,
      onRemoveFromPublicViewClicked,
      onModalConfirmRemoveFromPublicViewClicked,
      confirmModalRemoveFromPublicView,
      onModalConfirmDuplicateClicked,
      confirmModalDuplicate,
      onDuplicateClicked,
      duplicateForm,
      duplicateActivityInProgress,
      rules,
      formRef,
      onShowPublicSessions,
      activityTemplate,
      activitySettings,
      handleActivitySettingsVisibility
    }
  }
})
