
import { computed, defineComponent, ref } from 'vue'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

export default defineComponent({
  name: 'ready-switch',
  props: {
    template: Object,
    from: String
  },
  components: {

  },
  setup (props) {
    const template = computed(() => props.template as any)

    const store = useStore()
    const switchChecked = computed({
      get () {
        return template.value.status === 2
      },
      set (val) {
        template.value.status = val ? 2 : 1
      }
    })
    const makeReadyLoading = ref([]) as any

    const groupByEntity = [] as any
    groupByEntity.template = [] as any

    const findEntityInFails = (fails: [], entityUuid: string): any => {
      let entity = [] as any
      fails.forEach((failError: any, key) => {
        if (failError.part.entityUuid === entityUuid) {
          entity = failError.part
        }
        if (typeof failError.parent !== 'undefined' && failError.parent.entityUuid === entityUuid) {
          entity = failError.parent
        }
        if (typeof failError.grandParent !== 'undefined' && failError.grandParent.entityUuid === entityUuid) {
          entity = failError.grandParent
        }
      })

      return entity
    }

    const beforeMakeReady = () => {
      // add to loading
      makeReadyLoading.value.push(props.template!.activityTemplateUuid)
      return new Promise((resolve, reject) => {
        store.dispatch(template.value.status === 1 ? Actions.API_MAKE_READY_TEMPLATE : Actions.API_MAKE_DRAFT_TEMPLATE, props.template!.activityTemplateUuid).then((response) => {
          makeReadyLoading.value = makeReadyLoading.value.filter(uuid => uuid !== props.template!.activityTemplateUuid)
          if (props.from === 'latest') {
            store.commit(Mutations.UPDATE_ACTIVITY_TEMPLATE_IN_ACTIVITY_TEMPLATES, template.value)
          } else if (props.from === 'table') {
            store.commit(Mutations.UPDATE_ACTIVITY_TEMPLATE_IN_LATEST_TEMPLATES, template.value)
          }

          if (template.value.status === 1) {
            store.commit(Mutations.INCREASE_READY_TEMPLATES_COUNTER, 1)
            store.commit(Mutations.DECREASE_DRAFT_TEMPLATES_COUNTER, 1)
          } else if (template.value.status === 2) {
            store.commit(Mutations.INCREASE_DRAFT_TEMPLATES_COUNTER, 1)
            store.commit(Mutations.DECREASE_READY_TEMPLATES_COUNTER, 1)
          }

          return resolve(true)
        }).catch((response) => {
          makeReadyLoading.value = makeReadyLoading.value.filter(uuid => uuid !== props.template!.activityTemplateUuid)
          groupByEntity.template = [] as any
          if (response.data.error.code !== 'activityTemplate.entity.ready.could.not.be.validated') {
            Swal.fire({
              width: 600,
              title: 'Could not change status',
              html: response.data.error.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Okay',
              customClass: {
                container: 'ready-switch-html-container-error',
                confirmButton: 'btn fw-bold btn-light-danger'
              }
            })
            return
          }
          response.data.error.fails.forEach((failError, i) => {
            if (typeof failError.part.entity !== 'undefined') {
              if (failError.part.entity === 'template') {
                if (typeof groupByEntity.template[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template[failError.part.entityUuid] = []
                }
                groupByEntity.template[failError.part.entityUuid].push(failError)
              }
              if (failError.part.entity === 'module') {
                if (typeof groupByEntity.template.modules === 'undefined') {
                  groupByEntity.template.modules = []
                }
                if (typeof groupByEntity.template.modules[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template.modules[failError.part.entityUuid] = []
                }

                groupByEntity.template.modules[failError.part.entityUuid].push(failError)
              }

              if (failError.part.entity === 'section') {
                if (typeof groupByEntity.template.modules === 'undefined') {
                  groupByEntity.template.modules = []
                }

                if (typeof groupByEntity.template.modules[failError.parent.entityUuid] === 'undefined') {
                  groupByEntity.template.modules[failError.parent.entityUuid] = []
                }

                if (typeof groupByEntity.template.modules[failError.parent.entityUuid].sections === 'undefined') {
                  groupByEntity.template.modules[failError.parent.entityUuid].sections = []
                }

                if (typeof groupByEntity.template.modules[failError.parent.entityUuid].sections[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template.modules[failError.parent.entityUuid].sections[failError.part.entityUuid] = []
                }

                groupByEntity.template.modules[failError.parent.entityUuid].sections[failError.part.entityUuid].push(failError)
              }

              if (failError.part.entity === 'widget') {
                if (typeof groupByEntity.template.modules === 'undefined') {
                  groupByEntity.template.modules = []
                }

                if (typeof groupByEntity.template.modules[failError.grandParent.entityUuid] === 'undefined') {
                  groupByEntity.template.modules[failError.grandParent.entityUuid] = []
                }

                if (typeof groupByEntity.template.modules[failError.grandParent.entityUuid].sections === 'undefined') {
                  groupByEntity.template.modules[failError.grandParent.entityUuid].sections = []
                }

                if (typeof groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid] === 'undefined') {
                  groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid] = []
                }

                if (typeof groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid].widgets === 'undefined') {
                  groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid].widgets = []
                }

                if (typeof groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid].widgets[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid].widgets[failError.part.entityUuid] = []
                }

                groupByEntity.template.modules[failError.grandParent.entityUuid].sections[failError.parent.entityUuid].widgets[failError.part.entityUuid].push(failError)
              }
            }
          })

          let htmlErrors = '<ul aria-label="">'

          Object.keys(groupByEntity.template).forEach((validKey, key) => {
            if (validKey !== 'modules') {
              // template errors
              groupByEntity.template[validKey].forEach((templateError, tempKey) => {
                htmlErrors += '<li>' + templateError.message + '</li>'
              })
            } else {
              Object.keys(groupByEntity.template.modules).forEach((moduleErrorUuid, moduleKey) => {
                htmlErrors += '<ul aria-label="' + findEntityInFails(response.data.error.fails, moduleErrorUuid).positionOrdinal + ' module (' + findEntityInFails(response.data.error.fails, moduleErrorUuid).type + '): "> '

                Object.keys(groupByEntity.template.modules[moduleErrorUuid]).forEach((moduleKeyInside, mkey) => {
                  // module errors
                  if (moduleKeyInside !== 'sections') {
                    htmlErrors += '<li>' + groupByEntity.template.modules[moduleErrorUuid][moduleKeyInside].message + '</li>'
                  } else {
                    Object.keys(groupByEntity.template.modules[moduleErrorUuid].sections).forEach((sectionUuid, skey) => {
                      htmlErrors += '<ul aria-label="' + findEntityInFails(response.data.error.fails, sectionUuid).positionOrdinal + ' section: ">'

                      Object.keys(groupByEntity.template.modules[moduleErrorUuid].sections[sectionUuid]).forEach((sectionKeyInside, skinside) => {
                        // widget errors
                        if (sectionKeyInside !== 'widgets') {
                          htmlErrors += '<li>' + groupByEntity.template.modules[moduleErrorUuid].sections[sectionUuid][sectionKeyInside].message + '</li>'
                        } else {
                          Object.keys(groupByEntity.template.modules[moduleErrorUuid].sections[sectionUuid].widgets).forEach((widgetUuid, wkey) => {
                            htmlErrors += '<ul aria-label="' + findEntityInFails(response.data.error.fails, widgetUuid).positionOrdinal + ' widget (' + findEntityInFails(response.data.error.fails, widgetUuid).type + '): ">'
                            Object.keys(groupByEntity.template.modules[moduleErrorUuid].sections[sectionUuid].widgets[widgetUuid]).forEach((widgetErrorKey, widgetKey) => {
                              htmlErrors += '<li>' + groupByEntity.template.modules[moduleErrorUuid].sections[sectionUuid].widgets[widgetUuid][widgetErrorKey].message + '</li>'
                            })
                            htmlErrors += '</ul>'
                          })
                        }
                      })
                      htmlErrors += '</ul>'
                    })
                  }
                })
                htmlErrors += '</ul>'
              })
            }
          })
          htmlErrors += '</ul>'

          Swal.fire({
            width: 600,
            title: response.data.error.message,
            html: htmlErrors,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Okay',
            customClass: {
              container: 'ready-switch-html-container-error',
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
          return reject(new Error(response.data.error.message))
        })
      })
    }
    return {
      switchChecked,
      beforeMakeReady,
      makeReadyLoading
    }
  }
})
