
import { defineComponent, onMounted, ref } from 'vue'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default defineComponent({
  name: 'the-delete-activity-modal',
  props: {
    label: String,
    template: { type: Object, required: true }
  },
  emit: ['delete-template'],
  components: {},
  setup (props, { emit }) {
    const closeModal = ref(null)
    const templateToDelete = ref(props.template)
    const deleteActivityLoading = ref(false)
    const modalIsShown = ref(true)
    const store = useStore()
    onMounted(() => {

    })
    const handleDeleteActivity = (activityUuid) => {
      deleteActivityLoading.value = true

      store.dispatch(Actions.API_DELETE_ACTIVITY_TEMPLATE, activityUuid).then((response) => {
        deleteActivityLoading.value = false
        // close the modal by clicking the x icon
        document.getElementById('closeModal')?.click()
        emit('delete-template', activityUuid)
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }
    return {
      templateToDelete,
      deleteActivityLoading,
      handleDeleteActivity,
      modalIsShown,
      closeModal
    }
  }
})
