
import { defineComponent, ref } from 'vue'

interface Filter {
    status: string;
    scheduledFor: string;
}

export default defineComponent({
  name: 'the-sorting-dropdown',
  components: {},
  emits: ['sorted'],
  setup () {
    const sortSelection = ref<string>('lastOpened')
    const data = ref<Filter>({
      status: '1',
      scheduledFor: '1'
    })

    return {
      sortSelection,
      data
    }
  }
})
