import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createBlock(_component_el_switch, {
    modelValue: _ctx.switchChecked,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.switchChecked) = $event)),
    class: "ml-2",
    "active-value": true,
    "inactive-value": false,
    "active-text": "Ready",
    "inactive-text": "draft",
    loading: _ctx.makeReadyLoading.includes(_ctx.template.activityTemplateUuid),
    "before-change": _ctx.beforeMakeReady
  }, null, 8, ["modelValue", "loading", "before-change"]))
}